import Base from "./base";

export default class User extends Base {
  async changeEmail (email) {
    return await this.makePatchRequest("/users/change-email", { email });
  }

  async changeAvatar (avatar) {
    const form = new FormData();
    form.append("avatar", avatar);
    this.config["Content-Type"] = "multipart/form-data";
    return await this.makePostRequest("/users/change-avatar", form);
  }

  async deleteAvatar () {
    return await this.makeDeleteRequest("/users/delete-avatar");
  }

  async changePassword (newPassword) {
    return await this.makePatchRequest("/users/change-password", { password_new: newPassword });
  }

  async changeUsername (username) {
    return await this.makePatchRequest("/users/change-username", { username });
  }

  async get () {
    return await this.makeGetRequest("/users");
  }

  async update (params) {
    return await this.makePatchRequest("/users", params);
  }

  async getUserByUsername (username) {
    return await this.makeGetRequest(`/users/${username}`);
  }

  async getCounters (username) {
    return await this.makeGetRequest(`/users/${username}/counts`, { username });
  }

  async getReviews (username, params) {
    return await this.makeGetRequest(`/users/${username}/reviews`, params);
  }

  async getLists (username, params) {
    return await this.makeGetRequest(`/users/${username}/lists`, params);
  }

  async getItems (username, params) {
    return await this.makeGetRequest(`/users/${username}/items`, params);
  }

  async getCheckLater (username, params) {
    return await this.makeGetRequest(`/users/${username}/check-later-items`, params);
  }

  async getStatuses (username, params) {
    return await this.makeGetRequest(`/users/${username}/statuses`, params);
  }

  async getUserItemDetails (username, itemId, itemType) {
    return await this.makeGetRequest(`/users/${username}/items/${itemId}/${itemType}/details`);
  }

  async getFollowers (username) {
    return await this.makeGetRequest(`/users/${username}/followers`);
  }

  async getFollowings (username) {
    return await this.makeGetRequest(`/users/${username}/followings`);
  }
}
