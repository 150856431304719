<template>
  <span>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          outlined
          rounded
          v-bind="attrs"
          v-on="on"
        >
          Sign In
        </v-btn>
      </template>
      <v-card class="rounded-xl">
        <v-card-title>
          <span class="text-h5">Sign In</span>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-btn
            block
            center
            class="mb-4 rounded-xl"
            @click="googleLogin"
          >
            <v-icon class="mr-2">
              mdi-google
            </v-icon>

            Google
          </v-btn>
          <v-btn
            block
            center
            class="mb-4 rounded-xl"
            @click="twitterLogin"
          >
            <v-icon class="mr-2">
              mdi-twitter
            </v-icon>Twitter
          </v-btn>
          <div class="text-center font-weight-black">
            or
          </div>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email or Username"
                    :rules="emailRules"
                    :error-messages="emailMessages"
                    type="email"
                    required
                  />
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :error-messages="passwordMessages"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                  <div class="text-right">
                    <span
                      style="cursor: pointer;"
                      @click="dialog = false; passwordResetDialog = true"
                    >Reset Password</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-btn
                block
                class="py-4 rounded-xl"
                @click="login"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              Don't have an account yet? <span
                class="text-decoration-underline white--text cursor-pointer"
                @click="dialog = false; $root.$emit('eventFromLogin')"
              >Sign Up</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="passwordResetDialog"
      persistent
      max-width="400px"
    >
      <v-card class="rounded-xl">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h5">
              Reset Password
            </span>
            <v-spacer />
            <v-btn
              icon
              @click="passwordResetDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container v-if="!showSentPasswordMessage">
              <v-row>
                <v-col cols="12">
                  <p>
                    Enter your email address and we will send you a link to reset your password.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="emailToReset"
                    label="Email"
                    :rules="emailRules"
                    :error-messages="emailMessages"
                    type="email"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col cols="12">
                  <p>
                    We have sent you a password reset link to your email address.
                  </p>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions v-if="!showSentPasswordMessage">
              <v-btn
                block
                @click="resetPassword"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import login from "@/mixins/login";
import Auth from "@/services/auth";
import User from "@/services/user";

export default {
  name: "SignIn",
  mixins: [login],
  data: () => ({
    dialog: false,
    passwordResetDialog: false,
    email: "",
    emailToReset: "",
    showSentPasswordMessage: false,
    emailMessages: [],
    emailRules: [
      v => !!v || "E-mail or Username is required",
      // v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    password: "",
    showPassword: false,
    passwordMessages: [],
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length >= 8 || "Password must be at least 10 characters",
    ],
  }),
  watch: {
    email (val) {
      this.emailToReset = val;
    },
    passwordResetDialog (value) {
      if (value === false) {
        this.showSentPasswordMessage = false;
        this.emailToReset = "";
      }
    },
  },
  mounted () {
    this.$root.$on("eventFromBar", () => {
      this.dialog = true;
    });
  },
  methods: {
    async login () {
      if (!this.$refs.form.validate()) {
        return;
      }
      const authAPI = new Auth(this.$axios);
      await authAPI.auth(
        this.email,
        this.password,
      ).then(async (response) => {
        const userAPI = new User(this.$axios, {
          Authorization: `Bearer ${response.access_token}`,
        });
        await userAPI.get().then((user) => {
          this.$store.commit("setUser", { ...user, token: response.access_token });
          this.dialog = false;
        });
      }).catch((error) => {
        switch (error.response.status) {
          case 409:
            this.emailMessages = [error.response.data.detail];
            this.passwordMessages = [];
            break;
          case 404:
            this.emailMessages = [error.response.data.detail];
            this.passwordMessages = [];
            break;
          case 400:
            this.emailMessages = [];
            this.passwordMessages = [error.response.data.detail];
            break;
        }
      });
    },
    async resetPassword () {
      const authAPI = new Auth(this.$axios);
      await authAPI.resetPassword(this.emailToReset).then(() => {
        this.showSentPasswordMessage = true;
      }).catch((error) => {
        switch (error.response.status) {
          case 404:
            this.emailMessages = [error.response.data.detail];
            this.passwordMessages = [];
            break;
          case 400:
            this.emailMessages = [];
            this.passwordMessages = [error.response.data.detail];
            break;
        }
      });
    },
  },
};
</script>
